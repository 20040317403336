<template>
  <div class="concat-map-wrap">
    <div class="content-wrap">
      <div class="address">
        <div class="text">
          <span>{{ addressInfo.address }}</span
          ><br />
          <div class="address-info">
            <van-icon name="location" />
            <span v-html="addressInfo.detail"></span>
          </div>
        </div>
      </div>
      <div class="map-view">
        <div :id="'container' + addressInfo.active" class="map-content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import AMap from "AMap";
export default {
  components: {},
  props: ["addressInfo"],
  data() {
    return {
      map: ""
    };
  },
  mounted() {
    this.MapInit();
  },
  methods: {
    // 初始化地图 定位
    // 初始化
    MapInit() {
      let _this = this;
      const coordinates = this.addressInfo.coordinates;
      this.map = new AMap.Map("container" + _this.addressInfo.active, {
        center: coordinates,
        resizeEnable: true,
        zoom: 13
      });
      // 创建一个 Marker 实例：
      var marker = new AMap.Marker({
        position: new AMap.LngLat(coordinates[0], coordinates[1]) // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        // icon: '//vdata.amap.com/icons/b18/1/2.png', // 添加 Icon 图标 URL
        // title: '北京'
      });
      // 将创建的点标记添加到已有的地图实例：
      this.map.add(marker);
    }
  },
  beforeDestroy() {
    this.map && this.map.destroy();
  }
};
</script>

<style lang="less" scoped>
.concat-map-wrap {
  background-color: #ffffff;
  position: relative;
  .content-wrap {
    width: 953px;
    margin: 0 auto;
    text-align: left;
    .address {
      width: 953px;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .text {
        font-size: 36px;
        font-weight: bold;
        line-height: 30px;
        color: #333333;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .address-info {
          font-size: 28px;
          color: #666666;
          display: flex;
          align-items: center;
          overflow: hidden;
        }
      }
      .icon {
        width: 29px;
        height: 19px;
        transition: all linear 0.5s;
      }
      .icon-rotate.icon {
        transform: rotate(180deg);
        // transform-origin: center;
      }
    }
    .map-content {
      width: 100%;
      height: 390px;
      margin-top: 30px;
    }
  }

  .address-list {
    position: absolute;
    text-align: left;
    top: 308px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    max-height: 0;
    overflow: hidden;
    background-color: #fff;
    border-radius: 10px;
    border: solid 1px #e5e5e5;
    border-top: none;
    border-bottom: none;
    transition: all linear 0.5s;
    ul {
      li {
        width: 953px;
        height: 148px;
        border-radius: 10px;
        padding: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e5e5e5;
        transition: all linear 0.1s;
        .text {
          font-size: 36px;
          font-weight: bold;
          line-height: 30px;
          color: #333333;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .address-info {
            font-size: 28px;
            color: #666666;
            display: flex;
            align-items: center;
          }
        }
        .icon {
          width: 29px;
          height: 19px;
        }
      }
      li.actived {
        background-color: #c90f2c;
        .text,
        .address-info {
          color: #fff;
        }
      }
    }
    // border: none;
  }

  .address-list.list-show {
    max-height: 1000px;
    border-top: none;
  }
}
</style>
