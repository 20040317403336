<template>
  <div class="contact-wrap" :class="{ 'contact-wrap-pc': isPc }">
    <!-- banner -->
    <div class="banner-common" :class="{ 'banner-pc-common': isPc }">
      <img src="../../assets/img/concat-banner.png" alt="" v-if="!isPc" />
      <img src="../../assets/img-pc/concat-pc-banner.png" v-else alt="" />
      <div class="text">
        <span class="chanese">联系我们</span>
        <span class="english">contact us</span>
      </div>
    </div>

    <div class="content-wrap">
      <title-module :titleInfo="titleInfo" :isPc="isPc" v-if="isPc" />
      <!-- pic show -->
      <div class="pic-wrap">
        <div class="pic-item">
          <img src="../../assets/img/concat-1.png" v-if="!isPc" alt="" />
          <img src="../../assets/img-pc/swhezuo.png" v-else alt="" />
          <div class="text">
            <span class="remark">商务合作联系：</span>
            <span class="number">400-8820-919</span>
          </div>
        </div>
        <div class="pic-item">
          <img src="../../assets/img/concat-2.png" v-if="!isPc" alt="" />
          <img src="../../assets/img-pc/tzhezuo.png" v-else alt="" />
          <div class="text pd-lf20">
            <span class="remark">投资合作联系：</span>
            <span class="number">1525213860@qq.com</span>
          </div>
        </div>
      </div>

      <!-- map show -->
      <div class="map-wrap" v-if="!isPc">
        <title-module :titleInfo="mapInfo" class="title" :isPc="isPc" />
        <div class="map-item" v-for="item in adressInfo" :key="item.active">
          <mapView :addressInfo="item" />
        </div>
      </div>
      <mapPcView class="map-view-pc" v-else :isPc="isPc" />
    </div>
  </div>
</template>

<script>
import titleModule from "../../components/title";
import mapView from "./concat-map";
import mapPcView from "../index/components/mapPc";
export default {
  name: "contact",
  props: ["isPc"],
  components: {
    titleModule,
    mapView,
    mapPcView
  },
  data() {
    return {
      titleInfo: {
        titleRed: "合作",
        titleWhite: "联系",
        remark: "COOPERATION AND CONTACT"
      },
      mapInfo: {
        titleRed: "在线",
        titleWhite: "地图",
        remark: "ONLINE MAP"
      },
      adressInfo: [
        {
          address: "香港喜尔美（集团）投资管理有限公司",
          detail: "&nbsp;&nbsp香港新界葵涌葵昌路26-38号豪华工业大厦23楼B07室",
          active: 1,
          coordinates: [114.138204, 22.362083]
        },
        {
          address: "江西喜尔美喜庆用品连锁股份有限公司",
          detail: "&nbsp;&nbsp江西省宜春市万载县马步乡喜尔美烟花爆竹物流基地",
          active: 2,
          coordinates: [114.465828, 28.042409]
        },
        {
          address: "安徽儒彧电子信息科技有限公司",
          detail: "&nbsp;&nbsp安徽省合肥市高新区创新大道与皖水路交叉口",
          active: 3,
          coordinates: [117.133332, 31.847716]
        }
      ]
    };
  },

  methods: {},

  created() {}
};
</script>

<style lang="less" scoped>
@import "./contactPc.less";
.contact-wrap {
  .content-wrap {
    padding: 39px 45px 40px 47px;
    .pic-wrap {
      display: flex;
      justify-content: space-between;
      .pic-item {
        width: 501px;
        height: 513px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-content: center;
        background-color: #ffffff;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
        border-radius: 20px;
        img {
          height: 331px;
        }
        .text {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          span {
            padding-left: 50px;
          }
          .remark {
            font-size: 30px;
            font-weight: bold;
            line-height: 60px;
            color: #333333;
          }
          .number {
            font-size: 36px;
            font-weight: bold;
            color: #d4101e;
          }
        }
      }
    }
    .map-wrap {
      .title {
        padding: 48px 0;
      }
      .map-item {
        padding: 43px 42px 43px 39px;
        background-color: #ffffff;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
        border-radius: 20px;
        margin-bottom: 30px;
      }
    }
  }
}
</style>
