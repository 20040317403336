<template>
  <section class="map-container">
    <div class="map-content type-area">
      <!-- <div class="title" v-if="!type">
        <span>在线地图</span>
      </div> -->
      <titleModule class="title" :titleInfo="titleInfo" :isPc="isPc" />
      <div class="map-view" :class="{ 'us-map': type }">
        <div class="map-nav">
          <ul>
            <li @click="selectAdress(1)" :class="{ active: active === 1 }">
              <div class="compny">香港喜尔美（集团）投资管理有限公司</div>
              <div class="compny-adress">
                <van-icon name="location-o" />
                香港新界葵涌葵昌路26-38号豪华工业大厦23楼B07室
              </div>
            </li>
            <li @click="selectAdress(2)" :class="{ active: active === 2 }">
              <div class="compny">江西喜尔美喜庆用品连锁股份有限公司</div>
              <div class="compny-adress">
                <van-icon name="location-o" />
                江西省宜春市万载县马步乡喜尔美烟花爆竹物流基地
              </div>
            </li>
            <li @click="selectAdress(3)" :class="{ active: active === 3 }">
              <div class="compny">安徽儒彧电子信息科技有限公司</div>
              <div class="compny-adress">
                <van-icon name="location-o" />
                安徽省合肥市高新区创新大道与皖水路交叉口
              </div>
            </li>
          </ul>
        </div>
        <div class="map">
          <div id="container" class="map-content"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AMap from "AMap";
import titleModule from "@/components/title";
export default {
  props: ["type", "isPc"],
  components: {
    titleModule
  },
  data() {
    return {
      active: 1,
      map: "",
      titleInfo: {
        titleRed: "在线",
        titleWhite: "地图",
        remark: "ONLINE MAP"
      }
    };
  },
  computed: {
    destInfo() {
      let str = "";
      switch (this.active) {
        case 1:
          str = [114.138204, 22.362083]; //香港喜尔美（集团）投资管理有限公司
          break;
        case 2:
          str = [114.465828, 28.042409]; //江西喜尔美喜庆用品连锁股份有限公司
          break;
        case 3:
          str = [117.133332, 31.847716]; //安徽儒彧电子信息科技有限公司
          break;
      }
      return str;
    }
  },
  mounted() {
    const that = this;
    setTimeout(() => {
      that.selectAdress(1);
    }, 500);
  },
  methods: {
    selectAdress(val) {
      this.active = val;
      this.MapInit();
    },
    // 初始化地图 定位
    // 初始化
    MapInit() {
      let _this = this;
      this.map = new AMap.Map("container", {
        center: _this.destInfo,
        resizeEnable: true,
        zoom: 15
      });
      // 创建一个 Marker 实例：
      var marker = new AMap.Marker({
        position: new AMap.LngLat(_this.destInfo[0], _this.destInfo[1]) // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        // icon: '//vdata.amap.com/icons/b18/1/2.png', // 添加 Icon 图标 URL
        // title: '北京'
      });
      // 将创建的点标记添加到已有的地图实例：
      this.map.add(marker);
    }
  },
  beforeDestroy() {
    this.map && this.map.destroy();
  }
};
</script>
<style lang="less" scoped>
@import "../../../styles/mapPc.less";
</style>
